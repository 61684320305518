
import { addBackToTop } from 'vanilla-back-to-top';
const navbar = document.getElementById("navigation-container");
const footer = document.querySelector('.footer-section')
const header = document.querySelector('.header-section')

let entries = [footer, header]
let mobileScr = window.matchMedia("(max-width: 767px)")

let observer = new IntersectionObserver((entries)=>{
  entries.forEach(entry=>{
    if (entry.isIntersecting || mobileScr.matches){
       navbar.classList.remove("sticky");
    } else  {
      navbar.classList.add('sticky')
    }
  })
});


   entries.forEach(entry=>observer.observe(entry))
  



addBackToTop({
  backgroundColor: 'rgba(243, 116, 53, 0.7)',
  textColor: '#727376',
})