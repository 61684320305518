const menuBtnRef = document.querySelector('.menu-button');
const mobileMenuRef = document.querySelector('.nav-list');
const mobileMenuOverlayRef = document.querySelector('.navigation-overlay');
// const navBtnsRef = document.querySelectorAll('.nav-item');
// const navLinkRef = document.querySelectorAll('.nav-link');

menuBtnRef.addEventListener('click', toggleOpenClasses);

mobileMenuOverlayRef.addEventListener('click', toggleOpenClasses);

mobileMenuRef.addEventListener('click', toggleOpenClasses);

const anchors = document.querySelectorAll('a[href*="#"]');

for (let anchor of anchors) {
  anchor.addEventListener('click', function (e) {
    e.preventDefault();
    const blockID = anchor.getAttribute('href');
    document.querySelector('' + blockID).scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  });
}

function toggleOpenClasses() {
  const expanded = menuBtnRef.getAttribute('aria-expanded') === 'true' || false;

  menuBtnRef.classList.toggle('is-open');
  menuBtnRef.setAttribute('aria-expanded', !expanded);

  mobileMenuRef.classList.toggle('is-open');
  mobileMenuOverlayRef.classList.toggle('is-open');
}
