import axios from 'axios';

// axios.defaults.baseURL = 'https://stikeriback.herokuapp.com/api/';

axios.defaults.baseURL = 'https://api.stikeri.rs/api/';
// axios.defaults.baseURL = 'http://localhost:3777/api/'

const fetchSendOrder = info => axios.post('/sendemail', { result: info });
const fetchSendOrderFile = info =>
  axios.post('/sendfileorder', info, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

 const fetchSendConfirmation = (info, email)=> axios.post('/sendconfirmation', {text:info, email:email}) 
export { fetchSendOrder, fetchSendOrderFile, fetchSendConfirmation };
