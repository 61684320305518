var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "    Diameter, mm\n";
},"3":function(container,depth0,helpers,partials,data) {
    return " Prečnik, mm\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "\n    enter diameter, mm\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "  unesite prečnik,mm\n    ";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"input-container diameter\">\n  <label for=\"diameter-input\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"EN") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":7,"column":9}}})) != null ? stack1 : "")
    + "    </label>\n  <input type=\"number\" step=\"0.1\" placeholder=\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"EN") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":9,"column":48},"end":{"line":13,"column":11}}})) != null ? stack1 : "")
    + "\" id=\"diameter-input\" class=\"width input\" />\n</div>";
},"useData":true});