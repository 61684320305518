var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "      Width, mm\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "      Širina, mm\n      ";
},"5":function(container,depth0,helpers,partials,data) {
    return "\n      enter width, mm\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "     unesite širinu,mm\n      ";
},"9":function(container,depth0,helpers,partials,data) {
    return "\n  Height, mm\n";
},"11":function(container,depth0,helpers,partials,data) {
    return "  Visina, mm\n  ";
},"13":function(container,depth0,helpers,partials,data) {
    return "  \n      enter height, mm\n";
},"15":function(container,depth0,helpers,partials,data) {
    return "     unesite visinu,mm\n      ";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"input-container width\">\n  <label for=\"width-input\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"EN") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":3,"column":6},"end":{"line":7,"column":13}}})) != null ? stack1 : "")
    + "</label>\n  <input type=\"number\" step=\"0.1\" placeholder=\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"EN") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":8,"column":48},"end":{"line":12,"column":13}}})) != null ? stack1 : "")
    + "\" id=\"width-input-el\" class=\"width input\" />\n</div>\n<div class=\"input-container height\">\n  <label for=\"height-input\">"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"EN") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(11, data, 0),"data":data,"loc":{"start":{"line":15,"column":28},"end":{"line":19,"column":9}}})) != null ? stack1 : "")
    + "</label>\n  <input type=\"number\" step=\"0.1\" placeholder=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"EN") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.program(15, data, 0),"data":data,"loc":{"start":{"line":20,"column":47},"end":{"line":24,"column":13}}})) != null ? stack1 : "")
    + "\" id=\"height-input-el\" class=\"height input\" />\n</div>";
},"useData":true});