import alertTemplate from '../templates/alert.hbs';
import alertMOQTemplate from '../templates/alertmoq.hbs';
import resultTemplate from '../templates/result.hbs';
import diameterInputTemplate from '../templates/diameter.hbs';
import widthHeightElInputTemplate from '../templates/width_height_el.hbs';
import widthHeightInputTemplate from '../templates/width_height.hbs';
import actionBtnsTemplate from '../templates/action_buttons.hbs';
import onOrderClick from './orderForm';
const refs = {
  calculateBtn: document.querySelector('.calculate-button'),
  quantity: document.querySelector('#quantity-input'),
  goldFoil: document.querySelector('#foil-2'),
  result: document.querySelector('.result'),
  shape: document.querySelector('.choose-shape'),
  size: document.querySelector('.size'),
  square: document.querySelector('#shapep'),
  round: document.querySelector('#shapek'),
  elipse: document.querySelector('#shapee'),
  form: document.querySelector('.kalkulator-input'),
};

refs.shape.addEventListener('change', checkShape);
refs.form.addEventListener('submit', showResult);

let EN = window.location.href.includes('/en');

function checkShape() {
  if (refs.square.checked) {
    refs.size.innerHTML = widthHeightInputTemplate({ EN: EN });
  } else if (refs.round.checked) {
    refs.size.innerHTML = diameterInputTemplate({ EN: EN });
  } else if (refs.elipse.checked) {
    refs.size.innerHTML = widthHeightElInputTemplate({ EN: EN });
  }
}

function showResult(e) {
  e.preventDefault();

  let dimension = calculateSurface();

  if (dimension.surface <= 0) {
    refs.result.scrollIntoView({ behavior: 'smooth' });
    return (refs.result.innerHTML = alertTemplate({ EN: EN }));
  }

  let { moqCondition, moq, options } = calculatePrice(dimension);

  refs.result.innerHTML = resultTemplate(options);
  refs.final = document.querySelector('#final');
  refs.btns = document.querySelector('.action-btns');

  !moqCondition &&
    refs.btns.insertAdjacentHTML(
      'beforeend',
      alertMOQTemplate({ moq, EN: EN }),
    );

  refs.btns.insertAdjacentHTML('beforeend', actionBtnsTemplate({ EN: EN }));
  document
    .querySelector('.order-btn')
    .addEventListener('click', () => onOrderClick({ options, EN: EN }));

  refs.result.scrollIntoView({ behavior: 'smooth', block: 'end' });
}

function calculateSurface() {
  let surface = 0;
  let shape = '';
  let dimension = ``;
  if (refs.square.checked) {
    let width = document.querySelector('#width-input').value;
    let height = document.querySelector('#height-input').value;
    surface = width * height * refs.quantity.value;
    shape = refs.square.value;
    dimension = `${width}x${height}`;
  } else if (refs.round.checked) {
    let diameter = document.querySelector('#diameter-input').value;

    surface =
      ((((Math.PI.toFixed(2) * diameter) / 2) * diameter) / 2) *
      refs.quantity.value;
    shape = refs.round.value;
    dimension = `${diameter}`;
  } else if (refs.elipse.checked) {
    let widthEl = document.querySelector('#width-input-el').value;
    let heightEl = document.querySelector('#height-input-el').value;

    surface =
      ((((Math.PI.toFixed(2) * widthEl) / 2) * heightEl) / 2) *
      refs.quantity.value;
    shape = refs.elipse.value;
    dimension = `${widthEl}x${heightEl}`;
  }
  return { surface: surface, shape: shape, dimension: dimension };
}

function calculatePrice({ surface, shape, dimension }) {
  let NetPrice = (0.022 * surface).toFixed(2);
  let foil = EN ? `white` : `bela`;
  if (refs.goldFoil.checked) {
    NetPrice = (0.027 * surface).toFixed(2);
    foil = EN ? `silver or gold` : ` Srebrna ili zlatna`;
  }

  let onePcPrice = (NetPrice / refs.quantity.value).toFixed(2);
  let moq = Math.round(2000 / Number(onePcPrice));
  let moqCondition = NetPrice > 2000 ? true : false;
  let totalNetPrice = moqCondition
    ? Number(NetPrice)
    : Number(moq * onePcPrice);
  let taxAmount = (totalNetPrice / 5).toFixed(2);
  let totalBruttoPrice = (Number(totalNetPrice) + Number(taxAmount)).toFixed(2);
  let quantityDescription = moqCondition
    ? `Tražena količina stikera je`
    : `Minimalna količina*`;

  let quantityDescriptionEn = moqCondition ? `Quantity` : `Minimum quantity*`;

  let quantity = moqCondition ? refs.quantity.value : moq;

  let options = formOptions(
    shape,
    dimension,
    foil,
    quantity,
    onePcPrice,
    totalNetPrice,
    taxAmount,
    totalBruttoPrice,
    quantityDescription,
    quantityDescriptionEn,
  );

  let result = { moqCondition, moq, options };
  return result;
}

function formOptions(
  shape,
  dimension,
  foil,
  quantity,
  onePcPrice,
  totalNetPrice,
  taxAmount,
  totalBruttoPrice,
  quantityDescription,
  quantityDescriptionEn,
) {
  let onePcPriceEn = (Number(onePcPrice) / 117).toFixed(4);
  let totalNetPriceEn = (Number(totalNetPrice) / 117).toFixed(2);

  let options = [
    {
      description: EN ? `Shape is` : `Oblik stikera je`,
      value: shape,
    },
    {
      description: EN ? `Label's dimensions` : `Dimenzija stikera je`,
      value: `${dimension}mm`,
    },
    {
      description: EN ? `Foil is` : `Folija stikera je`,
      value: foil,
    },
    {
      description: EN ? quantityDescriptionEn : quantityDescription,
      value: EN ? `${quantity} pcs` : `${quantity} kom.`,
    },
    {
      description: EN ? `Price per piece` : `Cena stikera po komadu je`,
      value: EN
        ? `${onePcPriceEn} EUR`
        : `${String(onePcPrice).replace('.', ',')} RSD`,
    },
    {
      description: EN ? `Total Net Price` : `Cena neto za tiraž`,
      value: EN
        ? `${totalNetPriceEn} EUR`
        : `${String(totalNetPrice.toFixed(2)).replace('.', ',')} RSD`,
    },
    {
      description: EN ? `VAT` : `PDV`,
      value: EN
        ? `We're shipping EXW Serbia, so VAT is not included`
        : `${String(taxAmount).replace('.', ',')} RSD`,
    },
    {
      description: EN ? `Total` : ` Ukupno`,
      value: EN
        ? `${totalNetPriceEn} EUR`
        : `${String(totalBruttoPrice).replace('.', ',')} RSD`,
    },
  ];

  return options;
}
