var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "<span style=\"font-style: italic;\">Dear customer,</span><br></br>\n<span style=\"font-style: italic;\">Thank you for your trust!</span><br></br>\n<span style=\"font-style: italic;\">Your order is in process!</span><br></br>\n<span style=\"font-style: italic;\">We will contact you in case more information is needed</span><br></br>\n<span style=\"font-style: italic;\">or when your order will be ready.</span><br></br>\n<span style=\"font-style: italic;\">Kind regards,</span><br></br>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return " <span style=\"font-style: italic;\">Poštovani,</span><br></br>\n<span style=\"font-style: italic;\">Hvala na poverenju!</span><br></br>\n<span style=\"font-style: italic;\">Primili smo Vašu porudžbinu!</span><br></br>\n<span style=\"font-style: italic;\">Kontaktiraćemo Vas ukoliko bude potrebna dodatna informacija</span><br></br>\n<span style=\"font-style: italic;\">ili kada porudžbina bude spremna za preuzimanje.</span><br></br>\n<span style=\"font-style: italic;\">Srdačan pozrdav,</span><br></br>\n\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "<strong> Order summary</strong>\n";
},"7":function(container,depth0,helpers,partials,data) {
    return " <strong>Vaša porudžbina</strong>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <strong>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"description") : depth0), depth0))
    + ":</strong> <span style=\"color: #f37435;\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"value") : depth0), depth0))
    + "</span>\n<br></br>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<html>\n  <body style=\"color: #727376;\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"EN") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":3,"column":2},"end":{"line":18,"column":8}}})) != null ? stack1 : "")
    + "<img src=\"https://stikeri.rs/images/artspromotelogosmall.jpg\"/> <br></br>\n<span>T:</span> <span style=\"color: #f37435;\">+381113988377</span> </span>\n<span>E:</span> <span style=\"color: #f37435;\">info@arts.rs</span><br></br>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"EN") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":22,"column":0},"end":{"line":26,"column":8}}})) != null ? stack1 : "")
    + " <br></br>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"info") : depth0),{"name":"each","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":28,"column":0},"end":{"line":31,"column":9}}})) != null ? stack1 : "")
    + "\n  </body>\n\n</html>";
},"useData":true});