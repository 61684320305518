import '../images/gallery/stickersblack.jpg';
import '../images/gallery/stickersyellow.jpg';
import '../images/gallery/stickersalfa.jpg';
import '../images/gallery/stickersad.jpg';
import '../images/gallery/stickersemezetta.jpg';
import '../images/gallery/stikermainimage.jpg';
import '../images/gallery/stikerrounded.jpg';
import images from './gallery.json';
import Splide from '@splidejs/splide';

import sliderMarkupTemplate from '../templates/slider.hbs';

const sliderContainerRef = document.querySelector('.stickers-img');

sliderContainerRef.innerHTML = sliderMarkupTemplate(images.images);
// Create and mount the thumbnails slider.
var secondarySlider = new Splide('#secondary-slider', {
  rewind: true,
  fixedWidth: 100,
  fixedHeight: 64,
  isNavigation: true,
  gap: 10,
  focus: 'center',
  pagination: false,
  arrows: false,
  cover: true,
  breakpoints: {
    600: {
      fixedWidth: 66,
      fixedHeight: 40,
    },
  },
}).mount();

// Create the main slider.
var primarySlider = new Splide('#primary-slider', {
  type: 'fade',
  heightRatio: 0.7,
  pagination: false,
  arrows: false,
  cover: true,
  // autoplay: true,
  // fixedWidth: 800,
  // breakpoints: {
  //   900: {
  //     fixedWidth: 400,
  //   },
  //   500: {
  //     fixedWidth: 220,
  //   },
  // },
});

// Set the thumbnails slider as a sync target and then call mount.
primarySlider.sync(secondarySlider).mount();
