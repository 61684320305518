import orderFormTemplate from '../templates/order-form.hbs';
import mailMsgTemplate from '../templates/mailMsg.hbs';
import mailConfirmationTemplate from '../templates/mailConfirmationTemplate.hbs'
import loader from '../templates/loader.hbs';
import { fetchSendOrder, fetchSendOrderFile, fetchSendConfirmation } from '../services/fetchApi';

let EN = window.location.href.includes('/en');

function displayForm({ options, EN: EN }) {
  document.body.insertAdjacentHTML(
    'afterbegin',
    orderFormTemplate({ options, EN: EN }),
  );
  const overlay = document.querySelector('.formOverlay');
  overlay.classList.toggle('is-open');
  window.scrollTo({
    top: 10,
    behavior: 'smooth',
  });

  document.querySelector('#close').addEventListener('click', onClose);
  function onClose() {
    overlay.remove();
  }
}

export default function onOrderClick({ options, EN: EN }) {
  displayForm({ options, EN: EN });
  document
    .querySelector('#orderForm')
    .addEventListener('submit', e => onSubmitNoFile(e, options));

  const reader = new FileReader();
  const fileUploader = document.getElementById('fajl');

  fileUploader.addEventListener('change', event => {
    const files = event.target.files;
    const file = files[0];

    reader.readAsDataURL(file);

    reader.addEventListener('load', event => {
      const src = event.target.result;
      document
        .querySelector('#orderForm')
        .addEventListener('submit', e => onSubmit(e, options, src, file));
    });
  });
}

function onSubmitNoFile(e, options) {
  e.preventDefault();
  const form = document.querySelector('#orderForm');
  const formData = new FormData(form);
  const file = formData.get('fajl');
  if (file.name.length > 0) {
    return;
  } else {
    const name = formData.get('ime');
    const tel = formData.get('tel');
    const email = formData.get('email');
    const comment = formData.get('komentar');

    let result = [
      { description: 'ime', value: name },
      { description: 'tel', value: tel },
      { description: 'email', value: email },
      { description: 'komentar', value: comment },
      ...options,
    ];
    document.querySelector('#orderForm').innerHTML = loader();
    window.scrollTo({
      top: 15,
      behavior: 'smooth',
    });

    // sendConfirmation(result, email)

    const message = String(mailMsgTemplate(result));

    fetchSendOrder(message).then(res =>
      res.status === 200 ? sendConfirmation(result,email) : onError(),
    );

    
  }
}

function sendConfirmation (info, email) {
  const confirmationText = String(mailConfirmationTemplate({info, EN:EN}))  
  fetchSendConfirmation(confirmationText, email).then(res=> res.status === 200 ? onSentSuccess(): onError())
}

function onSentSuccess() {

  document.querySelector('#orderForm').innerHTML = EN
    ? ` <h2 class="successMsg" >Thank you!<br></br> Your order is sent successfully!</h2>`
    : ` <h2 class="successMsg" >Hvala!<br></br> Vaša porudžbina je uspešno poslata!</h2>`;
}
function onError() {
  document.querySelector('#orderForm').innerHTML = EN
    ? ` <h2 class="errorMsg" >Ups!<br></br> Something went wrong... <br></br> Please try again later!</h2>`
    : ` <h2 class="errorMsg" >Joooj!<br></br> Desila se greška! <br></br> Pokušajte ponovo malo kasnije!</h2>`;
}

function onSubmit(e, options, file) {
  e.preventDefault();

  const form = document.querySelector('#orderForm');
  const formData = new FormData(form);
  const name = formData.get('ime');
  const tel = formData.get('tel');
  const email = formData.get('email');
  const comment = formData.get('komentar');

  let result = [
    { description: 'ime', value: name },
    { description: 'tel', value: tel },
    { description: 'email', value: email },
    { description: 'komentar', value: comment },
    ...options,
  ];

  formData.append('result', String(mailMsgTemplate(result)));
  document.querySelector('#orderForm').innerHTML = loader();
  window.scrollTo({
    top: 15,
    behavior: 'smooth',
  });

  fetchSendOrderFile(formData).then(res =>
    res.status === 200 ? sendConfirmation(result,email) : onError(),
  );
}
