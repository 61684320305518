var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "        Doming labels\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "         Stikeri\n";
},"5":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <p> "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"description") : depth0), depth0))
    + " <span>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"value") : depth0), depth0))
    + "</span> </p>\n    ";
},"7":function(container,depth0,helpers,partials,data) {
    return "      Name\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "      Ime\n";
},"11":function(container,depth0,helpers,partials,data) {
    return "      Phone\n";
},"13":function(container,depth0,helpers,partials,data) {
    return "      Telefon\n";
},"15":function(container,depth0,helpers,partials,data) {
    return "        Comment\n";
},"17":function(container,depth0,helpers,partials,data) {
    return "        Komentar\n        ";
},"19":function(container,depth0,helpers,partials,data) {
    return "        Artwork\n";
},"21":function(container,depth0,helpers,partials,data) {
    return "        Priprema\n";
},"23":function(container,depth0,helpers,partials,data) {
    return "        Submit\n";
},"25":function(container,depth0,helpers,partials,data) {
    return "      Podtvrditi\n        ";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"formOverlay\">\n  <div class=\"modalOrderForm\">\n    <span class=\"material-icons icon-cross formClose\" id=\"close\"> close </span>\n    <div class=\"stikersOrder\">\n      <h2> \n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"EN") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":6,"column":8},"end":{"line":10,"column":16}}})) != null ? stack1 : "")
    + "         </h2>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"options") : depth0),{"name":"each","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":6},"end":{"line":14,"column":13}}})) != null ? stack1 : "")
    + "</div>\n    <form  id='orderForm' >\n    <label for=\"ime\"> \n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"EN") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data,"loc":{"start":{"line":17,"column":6},"end":{"line":21,"column":13}}})) != null ? stack1 : "")
    + "       <input type=\"text\" name=\"ime\" id=\"ime\" required> </label>\n    <label for=\"tel\"> \n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"EN") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.program(13, data, 0),"data":data,"loc":{"start":{"line":24,"column":6},"end":{"line":28,"column":13}}})) != null ? stack1 : "")
    + "       <input type=\"tel\" name=\"tel\" id=\"tel\" required> </label>\n    <label for=\"email\"> e-mail <input type=\"email\" name=\"email\" id=\"email\" required> </label>\n\n    \n    <label for=\"komentar\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"EN") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.program(17, data, 0),"data":data,"loc":{"start":{"line":34,"column":8},"end":{"line":38,"column":15}}})) != null ? stack1 : "")
    + "  <textarea cols=\"40\" rows=\"5\" class=\"comment\"  word-break=\"break-word\" type=\"text\" name=\"komentar\" id=\"komentar\"> </textarea> </label>\n    <label for=\"fajl\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"EN") : depth0),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.program(21, data, 0),"data":data,"loc":{"start":{"line":40,"column":8},"end":{"line":44,"column":15}}})) != null ? stack1 : "")
    + "        <input  type=\"file\" name=\"fajl\" id=\"fajl\"> </label>\n     <button class = \"formOrderBtn\" type=\"submit\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"EN") : depth0),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.program(25, data, 0),"data":data,"loc":{"start":{"line":47,"column":8},"end":{"line":51,"column":15}}})) != null ? stack1 : "")
    + "</button>\n    </form>\n  </div>\n\n</div>";
},"useData":true});