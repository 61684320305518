document
  .querySelector('.lang-switcher')
  .addEventListener('change', setUpLanguage);

function setUpLanguage() {
  const lang = document.querySelector('.lang-switcher').checked ? 'EN' : 'RS';
  console.log(window.location.href);

  if (lang === 'EN') {
    window.location.href = window.location.href
      .replace(/\?.*/, '')
      .concat('en');
  } else {
    window.location.href = window.location.href
      .replace(/\?.*/, '')
      .replace('/en', '');
  }
}

// Simulate a mouse click:
// window.location.href = 'http://www.w3schools.com';

// Simulate an HTTP redirect:
// window.location.replace('http://www.w3schools.com');
